import "./styles.css";

let clickCount = 0;
let phrase = "";
const descriptives = [
  "adulterous",
  "alcoholic",
  "pregnant",
  "birthing",
  "unbelievable",
  "back-breaking",
  "angry",
  "arrogant",
  "ghastly",
  "lovable",
  "bumbling",
  "dastardly",
  "guaranteed semi-authentic",
  "slightly used",
  "mighty",
  "naturally eroded",
  "delighfully nimble",
  "at-the-ready,",
  "blithering",
  "bluffing",
  "touchy-feely",
  "bull-headed",
  "discombobulated",
  "intimate",
  "risky",
  "jumbled and confused",
  "mile high",
  "famished",
  "dehydrated",
  "bow-legged",
  "stank",
  "confused",
  "cruel-hearted",
  "demanding",
  "basketball dunking",
  "drunken",
  "embiggened",
  "painfully swollen",
  "exaggerated",
  "over-the-shoulder",
  "inverted",
  "bling blang see that thang. Hey, it's a",
  "unsolved",
  "mysterious",
  "fiercely loyal",
  "fricken",
  "flickering",
  "flirting",
  "free-loading",
  "frisky",
  "glorious",
  "gangsta",
  "petrified",
  "scrambled",
  "enduring",
  "svelte",
  "skiddish",
  "completely obscene",
  "ambiguously-gendered",
  "trans",
  "cross-dressing",
  "greedy",
  "hateful",
  "house-broken",
  "cartwheeling",
  "gun-toting",
  "hyperactive",
  "expensive",
  "fancy",
  "horned",
  "jiggly",
  "sniffing",
  "snorting",
  "gobbling",
  "dizzy",
  "how about that dang",
  "I can't believe that dang",
  "have you seen that wild",
  "have you heard the joke about the donkey and the",
  "have you heard the story about the priest and the",
  "have you heard the story about the priest's",
  "seriously, have you seen that dang",
  "what would your mother say about the",
  "would you like a nice bowl of",
  "what would your great grandma say about that",
  "have you ever thought about",
  "do you have time for a",
  "when did you see the famous",
  "can you give me a",
  "gimme that",
  "stoned",
  "give me back my",
  "please get off of my",
  "let me tell you an interesting tale about a smarmy toad and a",
  "Can I tell you a story about a",
  "Sit right back and hear a tale, a tale about",
  "Gilligan! That's not a coconut! It's a",
  "Please put some deodorant on that",
  "I couldn't tell if that was you or a",
  "where were you when I found that",
  "can you sing a song about a",
  "why are you the only one paying for the",
  "was that you last night or a",
  "how do you explain that",
  "would you ever consider dating my",
  "can you suck out the poison from a",
  "is it just me or is that one smelly",
  "why would you give me that",
  "can someone explain why there is a",
  "clowns really enjoyed",
  "that's one messed up",
  "idiotic",
  "redneck",
  "proud",
  "good day, you saucy",
  "goodnight, you wacky",
  "nighty night, ",
  "top o' the morning to you, ",
  "caucasian",
  "infuriating",
  "insecure",
  "diabolical",
  "can't believe it was a",
  "mentally impaired",
  "misunderstood",
  "naked",
  "fellowship of the",
  "lord of the",
  "narrow-minded",
  "oldest known",
  "world's fastest",
  "fatter than average",
  "gold-digging",
  "out-of-control",
  "outnumbered",
  "pea-brained",
  "rebellious",
  "self-absorbed",
  "shaky",
  "shivering",
  "sickened",
  "slippery",
  "shady",
  "embiggened",
  "persnickety",
  "fraudulent",
  "stubborn",
  "territorial",
  "tripping",
  "twisted",
  "tiresome",
  "terrific",
  "questionable",
  "underhanded",
  "useless",
  "vengeful",
  "vile",
  "yapping",
  "zippy",
  "smelly",
  "grumpy",
  "lumpy",
  "imaginary",
  "silly",
  "crusty",
  "fabulous",
  "bodacious",
  "bawdy",
  "busty",
  "querulous",
  "aggitated",
  "scrawny",
  "urban",
  "suburban",
  "country",
  "pompous",
  "hopeful",
  "passionate",
  "prancing",
  "nude",
  "flamboyant",
  "flaming",
  "failing",
  "farting",
  "fart-filled",
  "fainting",
  "kennel-trained",
  "potty-trained",
  "house-trained",
  "I think that is a fake",
  "I think that is a soapy",
  "Let me introduce you to my",
  "deep fried",
  "Ablaze",
  "Abrupt",
  "Accomplished",
  "Active",
  "Adored",
  "Adulated",
  "Adventurous",
  "Affectionate",
  "Amused",
  "Amusing",
  "Animal-like",
  "Antique",
  "Appreciated",
  "Archaic",
  "Arrogant",
  "Astonished",
  "Audacious",
  "Authoritative",
  "Authoritarian",
  "Awestruck",
  "Beaming",
  "Bewildered",
  "Blissful",
  "Boisterous",
  "Booming",
  "Bouncy",
  "Breathtaking",
  "Bright",
  "Brilliant",
  "Bubbling",
  "Calm",
  "Calming",
  "Capricious",
  "Celestial",
  "Charming",
  "Cheerful",
  "Cherished",
  "Chilled",
  "Comical",
  "Commanding",
  "Companionable",
  "Confident",
  "Contently satisfied",
  "Courage",
  "Crazy",
  "Creepy",
  "Dancing",
  "Dazzling",
  "Delicate",
  "Delightful",
  "Demented",
  "Doomed and desirable",
  "Determined",
  "Devoted",
  "Dominant",
  "Dramatic",
  "Drawn out",
  "Dripping",
  "Dumbstruck",
  "Inflated",
  "Elegant",
  "Enchanted",
  "Energetic",
  "Enthusiastic",
  "Ethereal",
  "Exaggerated",
  "Exalted",
  "Expectant",
  "Expressive",
  "Exuberant",
  "Faint",
  "Fantastical",
  "Favorable",
  "Feral",
  "Feverish",
  "Fiery",
  "Floating",
  "Flying",
  "Folksy",
  "Fond",
  "Forgiven",
  "Forgiving",
  "Frickin' awesome",
  "Frenetic",
  "Frenzied",
  "Friendly, amorous",
  "Frosted",
  "Funny",
  "Furry",
  "Galloping",
  "Gaping",
  "Gentle",
  "Giddy",
  "Glacial",
  "Gladness",
  "Gleaming",
  "Gleeful",
  "Gorgeous",
  "Graceful",
  "Grateful",
  "Halting",
  "Happy",
  "Haunting",
  "Heavenly",
  "Hidden",
  "High-spirited",
  "Honor",
  "Hopeful",
  "Hopping",
  "Humble",
  "Hushed",
  "Hypnotic",
  "Illuminated",
  "Immense",
  "Imperious",
  "Impudent",
  "In charge",
  "Inflated",
  "Innocent",
  "Inspired",
  "Intimate",
  "Intrepid",
  "Jagged",
  "Joking",
  "Joyful",
  "Jubilant",
  "Kind",
  "Languid",
  "Larger than life",
  "Laughable",
  "Lighthearted",
  "Limping",
  "Linear",
  "Lively",
  "Lofty",
  "Lovely",
  "Lulling",
  "Luminescent",
  "Lush",
  "Luxurious",
  "Magical",
  "Masterful",
  "Merciful",
  "Merry",
  "Mischievous",
  "Misty",
  "Modest",
  "Moonlit",
  "Mysterious",
  "Mystical",
  "Mythological",
  "Nebulous",
  "Nostalgic",
  "On fire",
  "Overstated",
  "Partying",
  "Perfunctory",
  "Perky",
  "Perplexed",
  "Persevering",
  "Pious",
  "Playful",
  "Pleasurable",
  "Poignant",
  "Portentous",
  "Posh",
  "Powerful",
  "Pretty",
  "Prickly",
  "Prideful",
  "Princesslike",
  "Proud",
  "Puzzled",
  "Queenly",
  "Questing",
  "Quiet",
  "Racy",
  "Ragged",
  "Regal",
  "Rejoicing",
  "Relaxed",
  "Reminiscent",
  "Repentant",
  "Reserved",
  "Resolute",
  "Ridiculous",
  "Ritualistic",
  "Robust",
  "Running",
  "Sarcastic",
  "Scampering",
  "Scoffing",
  "Scurrying",
  "Sensitive",
  "Serene",
  "Shaking",
  "Shining",
  "Silky",
  "Silly",
  "Simple",
  "Singing",
  "Skipping",
  "Smooth",
  "Sneaky",
  "Soaring",
  "Sophisticated",
  "Sparkling",
  "Spell-like",
  "Spherical",
  "Spidery",
  "Splashing",
  "Splendid",
  "Spooky",
  "Sprinting",
  "Starlit",
  "Starry",
  "Startling",
  "Successful",
  "Summery",
  "Surprised",
  "Sympathetic",
  "Tapping",
  "Teasing",
  "Tender",
  "Thoughtful",
  "Thrilling",
  "Tingling",
  "Tinkling",
  "Tongue-in-cheek",
  "Totemic",
  "Touching",
  "Tranquil",
  "Treasured",
  "Trembling",
  "Triumphant",
  "Twinkling",
  "Undulating",
  "Unruly",
  "Urgent",
  "Veiled",
  "Velvety",
  "Victorious",
  "Vigorous",
  "Virile",
  "Walking",
  "Wild",
  "Witty",
  "Wondering",
  "Zealous",
  "Zestful",
  "I do say, that is one scary",
  "When this thing hits 88 miles per hour, we're going to see some"
];
const nouns = [
  "hunting ground",
  "lettuce",
  "kitty",
  "kitty cat",
  "little kitty",
  "french granny",
  "french chef",
  "antidepressant drug",
  "corn cake",
  "president of the night",
  "president of my wildest, wildest dreams",
  "dream-crushing boss",
  "future that I've always longed for",
  "candlestick maker",
  "coffee pot",
  "brethren",
  "national security",
  "stank",
  "crack",
  "useless brakes",
  "international spy",
  "sound barrier",
  "skinny woman",
  "private investor",
  "main people",
  "stock car",
  "elastic underpants",
  "telephone hole",
  "mad cow disease",
  "scourge upon the earth",
  "rough-skinned newt",
  "karate kick",
  "pistol-wielding civilian",
  "legal warrant",
  "people are hurt",
  "idea he suggested",
  "place of business",
  "double chin",
  "game of toes",
  "stoners",
  "Justin Bieber imitator",
  "Taylor Swift fan",
  "famous landscape painting",
  "hairy legs",
  "old irish cottage",
  "horse bed",
  "bucking bronco",
  "pocket flask",
  "liquid oxygen",
  "laser beams",
  "preventive strike",
  "vulgar panda drawing",
  "dingle berry",
  "reading party",
  "jail bond",
  "toothless rat",
  "superhero",
  "indirect expression",
  "messiness",
  "love of her life",
  "trust fund",
  "volcanic crater",
  "nosebleed",
  "travel guidebook",
  "electric fence",
  "dog collar",
  "chew toy",
  "spanking",
  "tiresome lung",
  "police squad",
  "face tattoo like Tyson",
  "sneaky criminal",
  "new family",
  "keepsake machete",
  "person I never want to see again",
  "keepsake model",
  "micro expression",
  "halfway smile",
  "veggie patty",
  "bedazzled eyebrows",
  "garden hose",
  "thug life",
  "contagious mental disorders",
  "sniffling mole",
  "sloppy sloppy joe",
  "sloppy italian",
  "sloppy italian painting",
  "surfing sloth",
  "hissy fit",
  "broccoli",
  "broccoli biscuit",
  "cheese bombs",
  "dog poo",
  "cat turds",
  "safe house",
  "halfway house",
  "dismount",
  "bird beak",
  "pigeon gizzard",
  "sheep shoulders",
  "bunny boo",
  "mystery poo",
  "toot balloon",
  "fart rocks",
  "mystery fart",
  "foot ointment",
  "pig with super powers",
  "patrolman",
  "pervert",
  "toilet seat",
  "haunted graveyard",
  "really tough guy",
  "bully",
  "twinkling of a starry night sky",
  "wrinkle",
  "pride",
  "parade",
  "personal line of credit",
  "credit card debt",
  "burger of cheese",
  "burger of ham",
  "cheese of swiss",
  "nuggets of chicken",
  "bacon... oh bacon... I need that bacon.",
  "mild mannered billionaire",
  "mental disorder",
  "sweet tailpipe",
  "boiling water",
  "tree stump",
  "snail snout",
  "deer antler",
  "armadillo face",
  "background story",
  "mood",
  "baby",
  "nibblets",
  "laughing hyena",
  "hyena laughing at your failure",
  "weed wacker",
  "man of my dreams",
  "suckling pig",
  "girl's afro",
  "mob of idiots",
  "crime scene",
  "fashion model",
  "gothic romance",
  "first born",
  "tire iron",
  "wrath of a thousand crying infants",
  "pancake house",
  "fever dream",
  "fancy dancer",
  "cabin nestled in the woods",
  "load of manure",
  "idea of the day",
  "rocket science",
  "ways of the world",
  "back alley meeting",
  "words amongst friends",
  "double agent",
  "mormon",
  "wild, wild sheep",
  "kind of gentle folk",
  "ice cream",
  "diplomatic negotiation",
  "marshmallow",
  "piglet",
  "feelings deep down inside",
  "sharp pain right around here",
  "totem pole",
  "sarcasm",
  "remnants of chaos",
  "sleepy astronaut",
  "serious questions",
  "general knowledge",
  "pile of decomposition",
  "vice president",
  "governor",
  "election results",
  "captain of the ship",
  "sarcasm",
  "hiking boots",
  "mound of questionable goo",
  "set of lips",
  "ninja",
  "barbie patrol",
  "cartwheeling nun",
  "propaganda",
  "gun-toting nuns",
  "nuns",
  "pirate song",
  "unsolved mystery",
  "grizzly bear under my bed",
  "crisis in my inner ear",
  "sphere of influence",
  "post-apocalyptic world",
  "temper tantrum",
  "boulder holder",
  "elegant question",
  "nature trail",
  "indestructible empire",
  "dentist drill",
  "dainty eskimo",
  "quesadilla melting on that car",
  "indiscriminate killer",
  "fancy mannequin",
  "monkey",
  "stick figure",
  "day before",
  "matrimonial law",
  "sealed off tomb",
  "unit of unholy depth",
  "broken promises",
  "broken thing. such a sad thing.",
  "notion of love",
  "geometry",
  "high school principal",
  "college dropout",
  "debt from when I bought that crypto",
  "prison friend",
  "inmate",
  "inmates",
  "bonk",
  "smack",
  "dirty whiplash",
  "incarceration",
  "devious behavior",
  "med student",
  "cloudy day",
  "shoe string",
  "body",
  "stirring stick",
  "people don't have a clue",
  "people who are the best at being themselves",
  "people make my dreams come true",
  "divergent thinking",
  "chipped coffee cup",
  "worthy tribute",
  "towelette",
  "cucumber helmet",
  "cucumber free-for-all",
  "butter biscuit",
  "rednecks",
  "amish folk",
  "vibrations",
  "leopard",
  "pillow",
  "roadblock",
  "cougar",
  "silly teeth",
  "disorder",
  "loud dude that just isn't nice",
  "toilet",
  "snakehead",
  "descending color",
  "merry bells",
  "merry-making",
  "knight’s service",
  "life raft",
  "radio announcement",
  "brainchild",
  "fake genius",
  "confusing situation",
  "manipulating douche",
  "microwaved eggs",
  "shivering toes",
  "natural history",
  "positive feedback",
  "modern times",
  "mistress",
  "animal psychology department",
  "sewing-machine operator",
  "light bulb",
  "jet ski",
  "constant reminder",
  "baby",
  "snake",
  "mathematics department",
  "video game",
  "musical chairs",
  "pancake",
  "skull and crossbones",
  "fluffy slippers",
  "nonbeliever",
  "phonograph record",
  "friendly clam",
  "willow",
  "trooper",
  "clan member",
  "bouncy ball",
  "tentacle",
  "police department",
  "bored child",
  "thunder legs",
  "abnormal behavior",
  "drug addict",
  "meeting with my mom",
  "accountant",
  "mimicker",
  "overlord",
  "informal request",
  "railroad worm",
  "nudist camp",
  "mental case",
  "court date",
  "blind date",
  "sheep dip",
  "elk sauce",
  "giggling doughboy",
  "toast",
  "polkadot",
  "pizza cutter",
  "locker room",
  "litter pan",
  "wiener",
  "chicken stew",
  "jungle psychology",
  "dance",
  "night sweets",
  "nightmares",
  "nighty",
  "extremity",
  "invisibility cloak",
  "biology lab",
  "thoughts in my head",
  "fairy tale",
  "pickpocket",
  "copper kettle",
  "bumblebee",
  "beer gut",
  "sea monster",
  "bloviating blowfish",
  "orangutan",
  "tree",
  "milk cow",
  "old turtle",
  "parachute pants",
  "squishy face",
  "belly button",
  "cat beans",
  "bird beak",
  "armpit",
  "turkey gizzard",
  "anthem of life",
  "grizzly bear in my backyard",
  "monster under my bed",
  "dueling grandmas",
  "grandma and grandpa",
  "funky uncle",
  "group of angry old white folks",
  "disgruntled mob of angry elderly folk",
  "patronage",
  "seizure salad",
  "goat",
  "duck",
  "duckling",
  "eggs",
  "omelet",
  "kerfuffle",
  "old-fashioned romantic",
  "cow utter",
  "donut victory",
  "snort",
  "scream",
  "song of hope",
  "mama",
  "papa",
  "circus clown",
  "teddy bear",
  "mountain motel",
  "business",
  "attraction",
  "eyeball",
  "goober",
  "wig",
  "boo boo",
  "pimple",
  "snorting sort"
];

const Button = (event) => {
  return (
    <div className="button-wrapper">
      <button
        className="generate btn text-white mt-4"
        onClick={() => Generate()}>
        Let's get silly!
      </button>
    </div>
  );
};

const Generate = () => {
  clickCount = clickCount + 1;
  // console.log(clickCount);
  let noun = nouns[Math.floor(Math.random() * nouns.length)];
  let descriptive =
    descriptives[Math.floor(Math.random() * descriptives.length)];

  // CHECK IF STARTS WITH VOWEL
  function startsWithVowel(word) {
    var vowels = "aeiouAEIOU";
    return vowels.indexOf(word[0]) !== -1;
  }
  // CHECK IF ENDS WITH "A"
  function endswithA(word) {
    return word.endsWith(" a");
  }
  // FIX GRAMMAR
  if (startsWithVowel(noun) && endswithA(descriptive)) {
    descriptive = descriptive.replace(" a", " an");
    //console.log("fixed grammar vowel: " + descriptive);
  }
  if (noun.endsWith("s")) {
    descriptive = descriptive.replace("that", "those");
    //console.log("fixed grammar plural: " + descriptive);
  }

  phrase = descriptive + " " + noun;
  phrase = phrase.toLowerCase();

  if (
    descriptive.includes("have ") ||
    descriptive.includes("did ") ||
    descriptive.includes("when ") ||
    descriptive.includes("where ") ||
    descriptive.includes("why ") ||
    descriptive.includes("what ") ||
    descriptive.includes("would ") ||
    descriptive.includes("is it ") ||
    descriptive.includes("can ") ||
    descriptive.includes("how ")
  ) {
    phrase += "?";
  }

  //console.log("randomNumber1: " + randomNumber1);
  //console.log("randomNumber2: " + randomNumber2);

  document.querySelector(".phrase-container").innerHTML =
    "<div class='slide-in-elliptic-top-fwd'>" + phrase + "</div>";

  /////////
  // CHANGE THE BUTTON OVER TIME
  if (clickCount > 20) {
    document.querySelector("button.generate").classList.add("mild");
    document.querySelector("button.generate").innerHTML =
      "Getting too silly...";
  }
  if (clickCount > 75) {
    document.querySelector("button.generate").classList.add("moderate");
    document.querySelector("button.generate").innerHTML = "Take a break.";
  }
  if (clickCount > 150) {
    document.querySelector("button.generate").classList.add("severe");
    document.querySelector("button.generate").innerHTML = "Please stop.";
  }
  if (clickCount > 200) {
    document.querySelector("button.generate").classList.add("defcon1");
    document.querySelector("button.generate").innerHTML = "Ouch.";
  }
  if (clickCount > 250) {
    document.querySelector("button.generate").classList.add("defcon2");
    document.querySelector("button.generate").innerHTML = "That hurt my soul.";
  }
  if (clickCount > 300) {
    document.querySelector("button.generate").classList.add("dead");
    document.querySelector("button.generate").innerHTML = "I died.";
  }
  if (clickCount > 400) {
    clickCount = 0;
    document
      .querySelector("button.generate")
      .classList.remove("mild moderate severe defcon1 defcon2 dead");
    document.querySelector("button.generate").innerHTML = "Make me laugh!";
  }

  // IF BROWSER HAS SPEECH SYNTHESIS
  if ("speechSynthesis" in window) {
    document.querySelector(".options").classList.remove("d-none");
    let checkBox = document.querySelector(".options .form-check #speak");

    // IF SPEAK IS SELECTED
    if (checkBox.checked === true) {
      speakPhrase(phrase);
    }
  }
};

const onSpeakClick = () => {
  if ("speechSynthesis" in window) {
    let checkBox = document.querySelector(".options .form-check #speak");

    // IF SPEAK IS SELECTED
    if (checkBox.checked === true) {
      speakPhrase(phrase);
    }
  }
};

///////// SPEECH SYNTHESIS
const speakPhrase = (phrase) => {
  setTimeout(function (msg) {
    msg = new SpeechSynthesisUtterance();

    const pitchMax = 1.8;
    const rateMax = 30;

    // VOICE
    const langRegex = /^en|it|fr|pl(-[a-z]{2})?$/i;
    let voices = speechSynthesis
      .getVoices()
      .filter((voice) => langRegex.test(voice.lang));
    let voice = voices[Math.floor(Math.random() * voices.length)];
    //console.log(voice);
    //msg.voice = voice;
    //msg.rate = (Math.random() * rateMax) / 30;
    msg.pitch = Math.random() * pitchMax;
    msg.text = phrase;
    //console.log("Rate: " + msg.rate);
    //console.log("Pitch: " + msg.pitch);
    speechSynthesis.cancel();
    speechSynthesis.speak(msg);
  }, 400);
};

const Header = () => {
  return (
    <header>
      <h1 className="text-muted">Silly Thought Machine</h1>
    </header>
  );
};
const Footer = () => {
  return (
    <footer>
      <p className="mb-0 text-muted">
        &copy;2022 sillythought.com |{" "}
        <a
          href="mailto:sillythought.com@gmail.com"
          target="_blank"
          rel="noreferrer"
          onClick={() => {}}>
          contact
        </a>
      </p>
    </footer>
  );
};
const PhraseContainer = () => {
  return (
    <section>
      <div className="h1 phrase-container display-1 pt-5 pb-5">
        <div className="fade-in">prepare for silliness</div>
      </div>
    </section>
  );
};
const Options = () => {
  return (
    <section>
      <div className="options pt-5 pb-5 d-none">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="speak"
            onClick={() => onSpeakClick()}
          />
          <label className="form-check-label text-muted" htmlFor="speak">
            Speak
          </label>
        </div>
      </div>
    </section>
  );
};

const Ad = () => {
  return (
    <>
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-6529046275139757"
        data-ad-slot="6680194509"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
    </>
  );
};
export default function App() {
  return (
    <div className="App p-3 pt-5 pb-5">
      <Header />
      <div className="site-container">
        <PhraseContainer />
        <Button />
        <Options />
        <Ad />
      </div>
      <Footer />
    </div>
  );
}
